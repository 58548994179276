export const theme = {
  colors: {
    themeColor: '#0072F0',
    themeColorBlack: '#333333',
    themeColorGreen: '#38cb89',
    textColor: '#000',
    headingColor: '#000000',
    headingColorTwo: '#d2a98e',
    headingColorDefault: '#086AD8',
    subHeadingColor: '#b0b0b0',
    borderColor: '#ededed',
    borderColorTwo: '#f8f8f8',
    borderDash: '#dddddd',
    white: '#ffffff',
    black: '#000000',
    primary: '#0072F0',
    primaryAccent: '#E4411A',
    secondary: '#868686',
    success: '#28a745',
    info: '#17a2b8',
    warning: '#ffc107',
    danger: '#dc3545',
    light: '#f8f9fa',
    bodyBgColor: '#ffffff',
    gray: '#F8F8F8',
    grayTwo: '#FBFBFB',
    grayThree: '#f6f2ed',
    silver: '#ababab',
    silverTwo: '#cccccc',
    kimberly: '#6d70a6',
    spring: '#F6F2ED',
    pattens: '#def0ff',
    yellow: '#E5DD52',
  },
  lineHeights: {
    text: 2,
    heading: 1,
    hero: 1.37,
  },
  fontFamily: {
    text: 'Open Sans',
    heading: 'Ubuntu',
    hero: 'Ubuntu',
    h4: 'Open Sans',
    h5: 'Open Sans',
    h6: 'Open Sans',
    fontAwesomePro: 'Font Awesome 5 Pro',
    fontAwesomeBrand: 'Font Awesome 5 Brands',
  },
  fontSizes: {
    text: '16px',
    hero: '46px',
    h1: '43px',
    h2: '36px',
    h3: '32px',
    h4: '30px',
    h5: '24px',
    h6: '16px',
    small: '16px',
  },
  fontWeight: {
    text: 400,
    heading: 400,
    hero: 500,
    h4: 600,
    h5: 600,
    h6: 600,
  },
  transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
  outlines: {
    boxShadow: '3px 3px 12px 0 rgba(128,128,128,0.50)',
    borderRadius: '13px',
    insetBoxShadow: 'inset 0 1px 13px 0 rgba(96,96,96,0.50)',
  },
  maxWidth: '1920px',
};

export const size = {
  xxlarge: '1699px',
  xlarge: '1599px',
  large: '1199px',
  medium: '991px',
  small: '767px',
  xsmall: '575px',
  xxsmall: '480px',
  xxlargeOnly: '1700px',
  xlargeOnly: '1600px',
  largeOnly: '1200px',
  mediumOnly: '992px',
  smallOnly: '768px',
  xsmallOnly: '576px',
  xxsmallOnly: '481px',
};

export const device = {
  xxlarge: `(max-width: ${size.xxlarge})`,
  xlarge: `(max-width: ${size.xlarge})`,
  large: `(max-width: ${size.large})`,
  medium: `(max-width: ${size.medium})`,
  small: `(max-width: ${size.small})`,
  xsmall: `(max-width: ${size.xsmall})`,
  xxsmall: `(max-width: ${size.xxsmall})`,
  xxlargeOnly: `(min-width: ${size.xxlargeOnly})`,
  xlargeOnly: `(min-width: ${size.xlargeOnly})`,
  largeOnly: `(min-width: ${size.largeOnly})`,
  mediumOnly: `(min-width: ${size.mediumOnly})`,
  smallOnly: `(min-width: ${size.smallOnly})`,
  xsmallOnly: `(min-width: ${size.xsmallOnly})`,
  xxsmallOnly: `(min-width: ${size.xxsmallOnly})`,
};

export interface ResponsiveStyles {
  xlarge?: ResponsiveStyle;
  large?: ResponsiveStyle;
  medium?: ResponsiveStyle;
  small?: ResponsiveStyle;
  xsmall?: ResponsiveStyle;
}

export interface ResponsiveStyle {
  pr?: string;
  pt?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  fontSize?: string;
  align?: string;
  lineHeight?: string | number;
  maxwidth?: string;
  fontWeight?: number | string;
  pb?: string;
  m?: string;
}

export interface StyledComponentsBaseStylingProps {
  as?: asOptions;
  fontSize?: string;
  fontWeight?: number | string;
  letterspacing?: string;
  color?: string;
  m?: string;
  mb?: string;
  mt?: string;
  ml?: string;
  mr?: string;
  pt?: string;
  pb?: string;
  lineHeight?: string | number;
  textalign?: string;
  align?: string;
  maxwidth?: string;
  texttransform?: string;
}

type asOptions =     
| 'p'
| 'a'
| 'span'
| 'strong'
| 'em'
| 'h1'
| 'h2'
| 'h3'
| 'h4'
| 'h5'
| 'h6';

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-humans-of-2030-groups-tsx": () => import("./../../../src/pages/humans-of-2030/groups.tsx" /* webpackChunkName: "component---src-pages-humans-of-2030-groups-tsx" */),
  "component---src-pages-humans-of-2030-index-tsx": () => import("./../../../src/pages/humans-of-2030/index.tsx" /* webpackChunkName: "component---src-pages-humans-of-2030-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-sign-up-tsx": () => import("./../../../src/pages/newsletter-sign-up.tsx" /* webpackChunkName: "component---src-pages-newsletter-sign-up-tsx" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-waitlist-tsx": () => import("./../../../src/pages/product-waitlist.tsx" /* webpackChunkName: "component---src-pages-product-waitlist-tsx" */),
  "component---src-pages-subscription-success-tsx": () => import("./../../../src/pages/subscription-success.tsx" /* webpackChunkName: "component---src-pages-subscription-success-tsx" */),
  "component---src-pages-venture-capital-js": () => import("./../../../src/pages/venture-capital.js" /* webpackChunkName: "component---src-pages-venture-capital-js" */),
  "component---src-pages-whitepapers-scenarios-of-2030-tsx": () => import("./../../../src/pages/whitepapers/scenarios-of-2030.tsx" /* webpackChunkName: "component---src-pages-whitepapers-scenarios-of-2030-tsx" */),
  "component---src-pages-whitepapers-tech-and-trust-tsx": () => import("./../../../src/pages/whitepapers/tech-and-trust.tsx" /* webpackChunkName: "component---src-pages-whitepapers-tech-and-trust-tsx" */),
  "component---src-pages-whitepapers-tech-trust-deficit-tsx": () => import("./../../../src/pages/whitepapers/tech-trust-deficit.tsx" /* webpackChunkName: "component---src-pages-whitepapers-tech-trust-deficit-tsx" */),
  "component---src-templates-author-template-author-template-js": () => import("./../../../src/templates/author-template/author-template.js" /* webpackChunkName: "component---src-templates-author-template-author-template-js" */),
  "component---src-templates-blog-list-blog-list-tsx": () => import("./../../../src/templates/blog-list/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-blog-list-tsx" */),
  "component---src-templates-blog-template-blog-template-js": () => import("./../../../src/templates/blog-template/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-blog-template-js" */),
  "component---src-templates-category-template-category-template-js": () => import("./../../../src/templates/category-template/category-template.js" /* webpackChunkName: "component---src-templates-category-template-category-template-js" */),
  "component---src-templates-personas-group-template-personas-group-tsx": () => import("./../../../src/templates/personas-group-template/personas-group.tsx" /* webpackChunkName: "component---src-templates-personas-group-template-personas-group-tsx" */),
  "component---src-templates-stories-page-template-stories-page-template-tsx": () => import("./../../../src/templates/stories-page-template/stories-page-template.tsx" /* webpackChunkName: "component---src-templates-stories-page-template-stories-page-template-tsx" */)
}


import { createGlobalStyle } from 'styled-components';
import SelectorIcon from 'assets/images/icons/selector-icon.png';
import leftShadowSlice from 'assets/images/bg/slice-left-shadow.png';
import leftRedSlice from 'assets/images/bg/slice-left-red.png';
import rightPurpleSlice from 'assets/images/bg/slice-right-purple.png';
import bottomBluePurpleGradient from 'assets/images/bg/banner-bottom-blue-to-purple.png';
import topLeftBluePurpleGradient from 'assets/images/bg/banner-top-left-blue-to-purple.png';
import topRightBluePurpleGradient from 'assets/images/bg/banner-top-right-blue-to-purple.png';
import topBlackGreyGradient from 'assets/images/bg/black-to-grey-gradient.png';
import topRightShadowCorner from 'assets/images/bg/interior-right-shadow.png';
import topLeftFullShadowCorner from 'assets/images/bg/interior-left-full-shadow.png';
import { device } from 'theme';

export const GlobalStyle = createGlobalStyle`

*, *::after, *::before {
  box-sizing: border-box;
}
html, body {
  height: 100%;
}
body {
  line-height: ${(props) => props.theme.lineHeights.text};
  font-size: ${(props) => props.theme.fontSizes.text};
  @media only screen and (max-width: 767px){
    font-size: 14px;
    line-height: 24px;
  }
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeight.text};
  visibility: visible;
  font-family: ${(props) => props.theme.fontFamily.text};
  color: ${(props) => props.theme.colors.textColor};
  position: relative;
  background-color: ${(props) => props.theme.colors.bodyBgColor};
  &.no-overflow {
    overflow: hidden;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: ${(props) => props.theme.colors.headingColor};
  line-height: ${(props) => props.theme.lineHeights.heading};
  margin-top: 0;
  margin-bottom: 0;
}
h1, h2, h3 {
  font-family: ${(props) => props.theme.fontFamily.heading};
  font-weight: ${(props) => props.theme.fontWeight.heading};
}
h1 {
  font-size: ${(props) => props.theme.fontSizes.h1};
  @media only screen and (max-width: 1199px){
    font-size: 43px;
  }
  @media only screen and (max-width: 991px){
    font-size: 40px;
  }
  @media only screen and (max-width: 767px){
    font-size: 34px;
  }
}
h2 {
  font-size: ${(props) => props.theme.fontSizes.h2};
  @media only screen and (max-width: 1199px){
    font-size: 36px;
  }
  @media only screen and (max-width: 991px){
    font-size: 36px;
  }
  @media only screen and (max-width: 767px){
    font-size: 30px;
  }
}
h3 {
  font-size: ${(props) => props.theme.fontSizes.h3};
  @media only screen and (max-width: 1199px){
    font-size: 32px;
  }
  @media only screen and (max-width: 991px){
    font-size: 32px;
  }
  @media only screen and (max-width: 767px){
    font-size: 32px;
  }
}
h4 {
  font-family: ${(props) => props.theme.fontFamily.h4};
  font-size: ${(props) => props.theme.fontSizes.h4};
  font-weight: ${(props) => props.theme.fontWeight.h4};
  @media only screen and (max-width: 1199px){
    font-size: 30px;
  }
  @media only screen and (max-width: 991px){
    font-size: 28px;
  }
  @media only screen and (max-width: 767px){
    font-size: 24px;
  }
}
h5 {
  font-family: ${(props) => props.theme.fontFamily.h5};
  font-weight: ${(props) => props.theme.fontWeight.h5};
  font-size: ${(props) => props.theme.fontSizes.h5};
  @media only screen and (max-width: 767px){
    font-size: 20px;
  }
}
h6 {
  font-family: ${(props) => props.theme.fontFamily.h6};
  font-weight: ${(props) => props.theme.fontWeight.h6};
  font-size: ${(props) => props.theme.fontSizes.h6};
}

p:last-child {
  margin-bottom: 0;
}
a, button {
  color: ${(props) => props.theme.colors.themeColor};;
  display: inline;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}
a, button, img, input, span {
  transition: ${(props) => props.theme.transition};
}
*:focus {
  outline: none !important;
}
a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}
.btn.focus, .btn:focus {
  outline: none;
  box-shadow: none;
}
a:hover, button:hover {
  text-decoration: none;
  color: ${(props) => props.theme.colors.textColor};
}
button, input[type="submit"] {
  cursor: pointer;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.mark, mark {
  padding: 0 0;
  background-color: transparent;
  color: ${(props) => props.theme.colors.primaryAccent};
}

select {
  padding: 3px 20px;
  height: 56px;
  max-width: 100%;
  width: 100%;
  outline: none;
  border: 1px solid ${(props) => props.theme.colors.borderColorTwo};
  border-radius: 5px;
  background: ${(props) =>
    props.theme.colors
      .borderColorTwo} url(${SelectorIcon}) no-repeat center right 20px;
  background-color: rgb(248, 248, 248);
  -moz-appearance: none;
  -webkit-appearance: none;
  &:focus {
    background: ${(props) =>
    props.theme.colors
      .borderColorTwo} url(${SelectorIcon}) no-repeat center right 20px !important;
  }
}

img, video{
  max-width: 100%;
}

::selection {
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.themeColor};
}
form {
  input:focus::-webkit-input-placeholder { color:transparent; }
  input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
  input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
  input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */
  input, textarea {
    &::placeholder {
        transition: ${(props) => props.theme.transition};
    }
  }
}
input[type="text"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="number"]:focus, input[type="tel"]:focus, input[type="range"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="time"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="color"]:focus, textarea:focus, select:focus, select:focus, textarea:focus {
  color: ${(props) => props.theme.colors.themeColor};
  border-color: ${(props) => props.theme.colors.themeColor};
}

.site-wrapper-reveal{
  background-color: ${(props) => props.theme.colors.white};
}

.wrapper {
  position: relative;
}

@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1200px;
  }
}

@media (min-width: 1600px){
  .gutter-xl-70{
    margin-right: -35px;
    margin-left: -35px;
    [class*="col"] {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
}

.xp-150 {
  @media (min-width: 1200px) {
    padding: 0 50px;
  }
  @media (min-width: 1600px) {
    padding: 0 150px;
  }
}

figure {
  margin: 0;
}

.left-shadow-slice {
  background-image: url(${leftShadowSlice});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: left 50%;
}

.left-red-slice {
  background-image: url(${leftRedSlice});
  background-repeat: no-repeat;
  background-position: left bottom;
}

.right-purple-slice {
  background-image: url(${rightPurpleSlice});
  background-repeat: no-repeat;
  background-position: right top;
}

.bottom-left-blue-purple-gradient {
  background-image: url(${bottomBluePurpleGradient});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.top-left-blue-purple-gradient {
  background-image: url(${topLeftBluePurpleGradient});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.top-right-blue-purple-gradient {
  background-image: url(${topRightBluePurpleGradient});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}
.top-black-grey-gradient {
  background-image: url(${topBlackGreyGradient});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.right-grey-gradient {
  background-image: linear-gradient(79deg, #FFFFFF 4%, #F5F5F5 90%);
}

.top-right-shadow-corner {
  background: url(${topRightShadowCorner});
  background-repeat: no-repeat;
  background-position: right top;
}

.top-left-full-shadow-corner {
  background: url(${topLeftFullShadowCorner});
  background-repeat: no-repeat;
  background-position: left top;
}

.md-no-bg-image {
  @media ${device.medium} {
    background-image: none;
  }
}

.sm-no-bg-image {
  @media ${device.small} {
    background-image: none;
  }
}

.xs-no-bg-image {
  @media ${device.xsmall} {
    background-image: none;
  }
}
`;

const loadableReady = require('@loadable/component').loadableReady;
const ReactDOM = require('react-dom');

const transitionDelay = 500;

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH' && !location.hash) {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else if (location.hash) {
    const element = document.getElementById(location.hash.replace('#', ''));

    window.setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: element ? element.offsetTop : 0,
      });
    }, transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay,
    );
  }
  return false;
};

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback);
    });
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { theme } from 'theme';
import 'assets/fonts/opensans/opensans.css';
import 'assets/fonts/ubuntu/ubuntu.css';
import 'assets/css/bootstrap.css';
import { GlobalStyle } from 'assets/css/main-style';
import ScrollToTop from 'components/ui/scroll-to-top';
import Transition from 'components/transition';
import { Helmet } from 'react-helmet';
import { SiteWrapper } from './layout.style';

const Layout = ({ children, location }) => {
  return (
    <ThemeProvider theme={theme}>
      <Transition location={location}>
        <SiteWrapper className="wrapper">
          <Helmet>
            <link
              rel="stylesheet"
              type="text/css"
              href="https://fonts.atbaux.com/atb.com/index.css"
            />
          </Helmet>
          <GlobalStyle />
          {children}
          <ScrollToTop />
        </SiteWrapper>
      </Transition>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
